<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <div class="card p-fluid w-full">
        <h5 class="mb-4">Dívida</h5>

        <Toast />

        <div class="grid formgrid">
          <div class="field col-8">
            <label for="nome">Nome</label>
            <InputText id="nome" v-model.trim="form.nome" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.nome }" />
            <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
          </div>

          <div class="field col-4">
            <label for="cpfCnpj">Documento</label>
            <InputText id="cpfCnpj" v-model.trim="form.cpfCnpj" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.cpfCnpj }" />
            <small class="p-error" v-if="submitted && !form.cpfCnpj">Documento é obrigatório.</small>
          </div>

          <div class="field col-12">
            <label for="descricao">Descrição</label>
            <InputText id="descricao" v-model.trim="form.descricao" required="true" autocomplete="off" />
          </div>

          <template v-for="(endereco, index) in form.enderecos" :key="index">
            <div class="field col-4">
              <label for="logradouro">Endereço</label>
              <InputText id="logradouro" v-model.trim="endereco.logradouro" />
            </div>

            <div class="field col-3">
              <label for="tipo">Tipo</label>
              <Dropdown id="tipo" v-model="endereco.tipo" :options="tiposEndereco" optionValue="value"
                optionLabel="label" />
            </div>

            <div class="col-2 field-radiobutton">
              <RadioButton id="enderecoPrincipal" v-model="endereco.principal" name="enderecoPrincipal" :value="true"
                @change="tratarPrincipal(form.enderecos, index)" />
              <label for="enderecoPrincipal">Principal</label>
            </div>

            <div class="field-radiobutton col-2"></div>

            <div class="col-1">
              <Button icon="pi pi-times" class="p-button-text ml-auto" v-if="form.enderecos.length > 1"
                @click="form.enderecos.splice(index, 1)" />
            </div>
          </template>

          <div class="col-12">
            <div class="col-4">
              <Button label="Adicionar endereço" icon="pi pi-plus" class="p-button-text ml-auto"
                @click="form.enderecos.push({})" />
            </div>
          </div>

          <template v-for="(telefone, index) in form.telefones" :key="index">
            <div class="field col-4">
              <label for="telefone">Telefone</label>
              <InputMask id="telefone" v-model.trim="telefone.telefone" mask="(99) 99999-9999" unmask />
            </div>

            <div class="field col-3">
              <label for="tipo">Tipo</label>
              <Dropdown id="tipo" v-model="telefone.tipo" :options="tiposTelefone" optionValue="value"
                optionLabel="label" />
            </div>

            <div class="col-2 field-radiobutton">
              <RadioButton v-model="telefone.principal" name="telefonePrincipal" :value="true"
                @change="tratarPrincipal(form.telefones, index)" />
              <label for="telefonePrincipal">Principal</label>
            </div>

            <div class="field-radiobutton col-2">
              <Tag :value="validatedValue(telefone.validado)" :severity="validatedSeverity(telefone.validado)"
                @click="telefone.validado = !telefone.validado" style="cursor: pointer"></Tag>
            </div>

            <div class="col-1">
              <Button icon="pi pi-times" class="p-button-text ml-auto" v-if="form.telefones.length > 1"
                @click="form.telefones.splice(index, 1)" />
            </div>
          </template>

          <div class="col-12">
            <div class="col-4">
              <Button label="Adicionar telefone" icon="pi pi-plus" class="p-button-text ml-auto"
                @click="form.telefones.push({})" />
            </div>
          </div>

          <template v-for="(email, index) in form.emails" :key="index">
            <div class="field col-4">
              <label for="email">E-mail</label>
              <InputText v-model.trim="email.email" />
            </div>

            <div class="field col-3">
              <label for="tipo">Tipo</label>
              <Dropdown v-model="email.tipo" :options="tiposEmail" optionValue="value" optionLabel="label" />
            </div>

            <div class="col-2 field-radiobutton">
              <RadioButton v-model="email.principal" name="emailPrincipal" :value="true"
                @change="tratarPrincipal(form.emails, index)" />
              <label for="emailPrincipal">Principal</label>
            </div>

            <div class="field-radiobutton col-2">
              <Tag :value="validatedValue(email.validado)" :severity="validatedSeverity(email.validado)"
                @click="email.validado = !email.validado" style="cursor: pointer"></Tag>
            </div>

            <div class="col-1">
              <Button icon="pi pi-times" class="p-button-text ml-auto" v-if="form.emails.length > 1"
                @click="form.emails.splice(index, 1)" />
            </div>
          </template>

          <div class="col-12">
            <div class="col-4">
              <Button label="Adicionar e-mail" icon="pi pi-plus" class="p-button-text ml-auto"
                @click="form.emails.push({})" />
            </div>
          </div>

          <div class="field col-6">
            <label for="identificadorContrato">Identificador de contrato</label>
            <InputText id="identificadorContrato" v-model.trim="form.identificadorContrato"
              :class="{ 'p-invalid': submitted && !form.identificadorContrato }" />
            <small class="p-error" v-if="submitted && !form.identificadorContrato">Identificador de contrato é
              obrigatório.</small>
          </div>

          <div class="field col-6">
            <label for="identificadorParcelas">Identificador de parcelas</label>
            <InputText id="identificadorParcelas" v-model.trim="form.identificadorParcelas"
              :class="{ 'p-invalid': submitted && !form.identificadorParcelas }" />
            <small class="p-error" v-if="submitted && !form.identificadorParcelas">Identificador de parcelas é
              obrigatório.</small>
          </div>

          <div class="field col-6">
            <label for="saldoDivida">Saldo da dívida</label>
            <InputNumber id="saldoDivida" v-model="form.saldoDivida" mode="currency" currency="BRL" locale="pt-BR" />
          </div>

          <div class="field col-6">
            <label for="valor">Valor principal</label>
            <InputNumber id="valor" v-model="form.valor" mode="currency" currency="BRL" locale="pt-BR"
              :class="{ 'p-invalid': submitted && !form.valor }" />
            <small class="p-error" v-if="submitted && !form.valor">Valor principal é obrigatório.</small>
          </div>

          <div class="field col-4">
            <label for="dataVencimento">Data de vencimento original</label>
            <Calendar id="dataVencimento" v-model="form.dataVencimento" :showIcon="true"
              :class="{ 'p-invalid': submitted && !form.dataVencimento }" />
            <small class="p-error" v-if="submitted && !form.dataVencimento">Data de vencimento original é
              obrigatório.</small>
          </div>

          <div class="field col-4">
            <label for="dataAcordo">Data de acordo</label>
            <Calendar id="dataAcordo" v-model="form.dataAcordo" :showIcon="true" />
          </div>

          <div class="field col-4">
            <label for="diasAtraso">Dias em atraso</label>
            <InputNumber id="diasAtraso" v-model="form.diasAtraso" :useGrouping="false" />
          </div>

          <div class="field col-6">
            <label for="carteira">Carteira</label>
            <Dropdown id="carteira" v-model="form.carteira" :options="carteiras" optionLabel="descricao" dataKey="id"
              placeholder="Selecione a carteira" :class="{ 'p-invalid': submitted && !form.carteira }"
              @change="form.politicaNegociacao = $event.value.politicaNegociacao"></Dropdown>
            <small class="p-error" v-if="submitted && !form.carteira">Carteira é obrigatória.</small>
          </div>

          <div class="field col-6">
            <label for="politicaNegociacao">Política de negociação</label>
            <Dropdown id="politicaNegociacao" v-model="form.politicaNegociacao" :options="politicas"
              optionLabel="descricao" dataKey="id" disabled />
          </div>

        </div>

        <div class="flex mt-5">
          <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
          <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto"
            @click="$router.push('/dividas')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'

export default {
  data() {
    return {
      form: {},
      submitted: false,
      tiposTelefone: [
        { value: 'CELULAR', label: 'Celular' },
        { value: 'RESIDENCIAL', label: 'Residencial' },
        { value: 'COMERCIAL', label: 'Comercial' },
      ],
      tiposEndereco: [
        { value: 'RESIDENCIAL', label: 'Residencial' },
        { value: 'COMERCIAL', label: 'Comercial' },
      ],
      tiposEmail: [
        { value: 'PESSOAL', label: 'Pessoal' },
        { value: 'PROFISSIONAL', label: 'Profissional' },
      ],
      carteiras: [],
      politicas: []
    }
  },
  async mounted() {
    this.service = new CrudService('/admin/dividas')
    this.serviceCarteiras = new CrudService('/admin/carteiras')
    this.servicePoliticas = new CrudService('/admin/politica-negociacao')

    this.loadCarteiras();
    this.loadPoliticas();

    if (this.$route.params.id) {
      const { data } = await this.service.findById(this.$route.params.id)
      this.form = data
    }

    if (!this.form.telefones?.length) {
      this.form.telefones = [{}]
    }

    if (!this.form.enderecos?.length) {
      this.form.enderecos = [{}]
    }

    if (!this.form.emails?.length) {
      this.form.emails = [{}]
    }
  },
  methods: {
    async loadCarteiras() {
      const { data } = await this.serviceCarteiras.findAll({ politicaNegociacao: true });
      this.carteiras = data.items;
    },
    async loadPoliticas() {
      const { data } = await this.servicePoliticas.findAll();
      this.politicas = data.items;
    },
    async save() {
      this.submitted = true

      const deepForm = JSON.parse(JSON.stringify(this.form));

      if (deepForm.telefones?.length === 1 && !deepForm.telefones[0].telefone) {
        delete deepForm.telefones;
      }

      if (deepForm.emails?.length === 1 && !deepForm.emails[0].email) {
        delete deepForm.emails;
      }

      if (deepForm.enderecos?.length === 1 && !deepForm.enderecos[0].logradouro) {
        delete deepForm.enderecos;
      }

      try {
        await this.service.save(deepForm)
        this.$toast.add({
          severity: 'success',
          summary: 'Usuário gravado com sucesso!',
          life: 3000
        })
        this.$router.push('/dividas')
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar o usuário!',
          life: 3000
        })
        console.log(err)
      }
    },
    tratarPrincipal(list, mainIndex) {
      for (const [index, item] of list.entries()) {
        if (index !== mainIndex) {
          item.principal = false;
        }
      }
    },
    validatedValue(validated) {
      return validated ? 'Validado' : 'Não-validado';
    },
    validatedSeverity(validated) {
      return validated ? 'success' : 'warning';
    }
  }
}
</script>

<style scoped lang="scss">

</style>
